import PropTypes from "prop-types"
import React from "react"

export const AppContext = React.createContext({})
export const AppDispatchContext = React.createContext({})

export const useAppContext = () => {
  const context = React.useContext(AppContext)
  if (context === undefined) {
    throw new Error("useAppContext must be used within a AppContextProvider")
  }
  return context
}

export const useAppContextDispatch = () => {
  const context = React.useContext(AppDispatchContext)
  if (context === undefined) {
    throw new Error(
      "useAppContextDispatch must be used within a AppContextProvider"
    )
  }
  return context
}

export const AppContextProvider = ({
  isMobileDevice,
  host,
  acceptsCookies,
  children,
}) => {
  const [value, setValue] = React.useState({
    isMobileDevice,
    host,
    acceptsCookies,
    audioNotAllowed: false,
  })

  return (
    <AppContext.Provider value={value}>
      <AppDispatchContext.Provider value={setValue}>
        {children}
      </AppDispatchContext.Provider>
    </AppContext.Provider>
  )
}

AppContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  isMobileDevice: PropTypes.bool,
  acceptsCookies: PropTypes.bool,
  host: PropTypes.string,
}

AppContextProvider.defaultProps = {
  isMobileDevice: undefined,
  acceptsCookies: undefined,
  host: undefined,
}
